<template>
  <div class="d-flex justify-content-between mt-1 px-2">
    <div>
      {{ nama }} /
      {{ kelas }}
      /
      {{ semester}}
    </div>
    <div>page {{page}} of {{ totalPage }}</div>
  </div>
</template>

<script>
export default {
  props: ['nama', 'kelas', 'semester', 'page', 'totalPage'],
}
</script>

<style>

</style>
